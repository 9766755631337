table {
  border-collapse: collapse;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: lightgrey;
}
h3 {
  font-size: 1.25rem;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 5px;
}

td,
th {
  text-align: left;
  padding: 8px;
}

#showData {
  margin-bottom: 25px;
}
