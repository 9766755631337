.Select-Standort-Container {
  min-width: 200px;
  z-index: 50;
  display: flex;
  flex-grow: 1;
  margin: 15px;
}

.Select-Standort-Item {
  flex: 1;
}

#Affiliated-Standort-Container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 100px;
}

#Affiliated-Standort-Item {
  padding: 8px;
  white-space: nowrap;
  /* width: 200px; */
  /* height: 400px; */
}

#Affiliated-Members-Menu-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

#Affiliated-Members-Menu-Item {
  padding: 8px;
  white-space: nowrap;
  text-align: center;
}

.Default-Sort-Select-Container {
  display: flex;
  justify-content: center;
}
