.brand__link {
    color: white !important;
}

.sidenav__list-item-active {
    background-color: rgba(255, 255, 255, 0.2) !important;
    cursor: pointer;
  }

  /* Medium-sized screen breakpoint (tablet, 1050px) */
  @media only screen and (min-width: 65.625em) {
    /* Break out main cards into two columns */
    .main-cards {
      column-count: 2;
    }
  }

  .navbar-bottom {
    overflow: hidden;
    background-color: #3333338a;
    position: relative;
    align-items: stretch;
  }

  .navbar-bottom-container {
    /* position: fixed;
    bottom: 0;
    width: 240px; */
    margin-top: auto;
  }
  
  .navbar-bottom a {
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    width: 33%;
  }
  
  .navbar-bottom a:hover {
    background: #f1f1f1;
    color: black;
  }
  
  .navbar-bottom a.active {
    background-color: #5bb5ff;
    color: white;
  }

  .navbar-bottom-child {
    display: flex;
  }

  @media screen and (max-width: 700px) {
    .navbar-bottom {
      width: 100%;
      height: auto;
      position: relative;
    }
    .navbar-bottom a {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 100px) {
    .navbar-bottom a {
      text-align: center;
      float: none;
    }
  }