#vertragsnehmer {
text-align: center;
font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
border-collapse: collapse;
border: 3px solid #ddd;
width: 100%;
}

#vertragsnehmer td, #vertragsnehmer th {
border: 1px solid #ddd;
padding: 8px;
}

#vertragsnehmer tr:nth-child(even){background-color: #f2f2f2;}

#vertragsnehmer tr:hover {background-color: #ddd;}

#vertragsnehmer th {
padding-top: 12px;
padding-bottom: 12px;
text-align: center;
background-color: #686868;
color: white;
}
