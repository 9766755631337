:root {
    --spinner-size: 30vmin;
    --spinner-black: black;
    --spinner-white: white;
    --spinner-rotation: 10s;
}

.spinner-container,
.spinner-container * {
   box-sizing: content-box;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner-text-loading {
    text-align: center;
    margin-top: 5rem;
}

.spinner-text-loading > h2 {
    font-family: earth;
    font-size: 3rem;
    color: lightgoldenrodyellow;
    letter-spacing: 0.25em;
}

.glow {
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

/* text-shadow: h-shadow v-shadow blur-radius color|none|initial|inherit; */

@keyframes glow {
    from {
        text-shadow: 0 0 1px #fff, 0 0 5px #fff, 0 0 10px #e6cf00
      }
    
    
    to {
      text-shadow: 0 0 5px #fff, 0 0 15px #fff34d 0 0 25px #fff34d
    }
  }

.yy-spinner {
    display: flex;
    align-items: center;
    
    width: var(--spinner-size); height: var(--spinner-size);
    border-radius: 50%;
    background: linear-gradient(var(--spinner-black) 50%, var(--spinner-white) 0);
}

.yy-spinner:before, .yy-spinner:after {
    flex: 1;
    height: calc(var(--spinner-size)/6);
    border-radius: 50%;
    background: var(--spinner-black);
    content: '';
    border: solid calc(var(--spinner-size)/6) var(--spinner-white);
}

.yy-spinner:after {
    background: var(--spinner-white);
    border-color: var(--spinner-black);
}

@media (prefers-reduced-motion: no-preference) {
    .yy-spinner {
      animation: App-logo-spin infinite var(--spinner-rotation) linear;
    }
  }

  @keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
    }