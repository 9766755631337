.login__logo {
  width: 35%;
  border-radius: 20px;
  border-style: solid;
  border-color: black;
  border-width: 3px;
}

.stay-loggedin {
  margin-top: 5px;
  font-size: 1.2rem;
}

.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;
  opacity: 0.6;
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
  z-index: -5;
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}
