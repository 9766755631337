.register-background,
.register-background:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  margin: 0;
}
.register-background:after {
  content: "";
  background: rgb(36, 13, 0);
  background: radial-gradient(
    circle,
    rgba(36, 13, 0, 1) 0%,
    rgba(255, 255, 255, 0.5046219171262255) 0%,
    rgba(0, 0, 0, 0.3673670151654411) 100%
  );
}

.register-background li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  animation: bgAnimation;
  animation-duration: 50s;
  animation-fill-mode: forwards;
}

.register-background li:nth-child(1) span {
  background-image: url(./stone_sahbumnim.jpg);
}

.login__logo {
  max-width: 15vh;
  border-radius: 20px;
  border-style: solid;
  border-color: black;
  border-width: 3px;
}

.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;
  opacity: 0.6;
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
  z-index: -5;
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}

@keyframes bgAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
  }
  /* 8% { opacity: 0.25 }
  17% { opacity: 0.5 }
  25% { opacity: 0.75 } */
  100% {
    opacity: 1;
  }
}
