#beltContainer {
  display: flex;
}

#textContainer {
  text-align: center;
}

#beltText {
  text-align: left;
  margin-right: 10px;
  white-space: nowrap;
}
