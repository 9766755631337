#letterContainer {
  display: flex;
  justify-content: space-evenly;
}

#letter {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
}

#letter:hover {
  text-decoration: underline;
}

#buttonContainer {
  padding: 10px;
}
