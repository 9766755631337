body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'earth';
  src: url('../src/fonts/webfontEarth/earth-webfont.woff2') format('woff2'),
    url('../src/fonts/webfontEarth/earth-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

a {
  text-decoration: none;
}

a.active {
  background-color: rgb(162, 130, 189) !important;
  cursor: pointer;
  z-index: 500;
}


.main {
  grid-area: main;
  background-color: #f7f7f7;
}

.main > h1 {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.main-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: 150px; /* Force our height since we don't have actual content yet */
  background-color: #e3e4e6;
  color: slategray; 
}

.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr)); /* Where the magic happens */
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px;
}

.overviewcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}

.main-cards {
  column-count: 1;
  column-gap: 20px;
  margin: 20px;
  }

  .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #82bef6;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  }

  /* Force varying heights to simulate dynamic content */
  .card:first-child {
  height: 485px;
  }

  .card:nth-child(2) {
  height: 200px;
  }

  .card:nth-child(3) {
  height: 265px;
  }

  * {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
  }
