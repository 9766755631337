.Construction-container {
  text-align: center;
}

.Construction-header {
  font-size: 5vmin !important;
}

.Construction-logo {
  margin-top: 10vmin;
  margin-bottom: 10vmin;
  height: 40vmin;
  pointer-events: none;
}
