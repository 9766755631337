#birthday-row {
  /* background-color: #ffcc36; */
}

.birthday-glow {
  -webkit-animation: birthday-glow 3s ease-in-out infinite alternate;
  -moz-animation: birthday-glow 3s ease-in-out infinite alternate;
  animation: birthday-glow 3s ease-in-out infinite alternate;
}

/* text-shadow: h-shadow v-shadow blur-radius color|none|initial|inherit; */

@keyframes birthday-glow {
  from {
    background-color: #ffcc36;
  }

  to {
    background-color: rgb(0, 0, 0);
    color: white;
  }
}
